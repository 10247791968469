html {
  background: url(./images/mountains.png) no-repeat center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  animation: fadeOut 2s;
}

.hidebg {
  background-color: transparent;
  animation: fadeIn 2s;
}
.container {
  background-color: transparent;
  animation: fadeIn 2s;
}
@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: rgb(168, 194, 221);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(213, 243, 255, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(207, 207, 207, 0.2);
}

.links {
  font-family: monospace;
}
